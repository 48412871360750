<template>
  <section class="space-y-4">
    <quick-info />
    <article v-if="canCreateStationTransfer">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4">
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="create"
          icon="duplicate"
          text="Create Station Transfer"
        >
        </asom-button>
      </section>
    </article>
    <article>
      <asom-alert
        class="mb-4"
        v-if="error"
        variant="error"
        :error-message="error"
      />
      <div class="w-full text-center" v-else-if="isLoading">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <asom-client-table v-else
        :data="list"
        :columns="[
          'referenceNo',
          'dateCreated',
          'time',
          'dws'
        ]"
        :sortableColumns="[
          'referenceNo',
          'dateCreated',
          'dws'
        ]"
        :searchableDateColumns="['dateCreated']"
        :searchableTimeColumns="['dateCreated']"
      >
        <template v-slot:header_dws>Shift</template>
        <template v-slot:header_dateCreated>Date</template>
        <template v-slot:referenceNo="{ rowData }">
          <asom-link @click="viewDetails(rowData)">{{rowData.referenceNo}}</asom-link>
        </template>
        <template v-slot:dateCreated="{data}">{{displayUtcDate(data)}}</template>
        <template v-slot:time="{rowData}">{{displayUtcTime(rowData.dateCreated)}}</template>
      </asom-client-table>
    </article>
  </section>
</template>
<script>
import get from 'lodash.get'
import { mapGetters } from 'vuex';
import { displayUtcDate, displayUtcTime } from '@/helpers/dateTimeHelpers'
import { getStationTransferList } from '../../../../services/cashManagement.service';
import QuickInfo from "../../_QuickInfo.vue";

export default {
  components: {
    QuickInfo
  },
  data() {
    return {
      error: null,
      isLoading: false,
      list: []
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      canCreateStationTransfer: 'auth/canCreateStationTransfer',
    })
  },
  mounted() {
    this.loadData();
  },
  methods: {
    displayUtcDate,
    displayUtcTime,
    async loadData() {
      this.error = null;
      this.isLoading = true;
      const result = await getStationTransferList(this.stationId, this.lineId);
      if (result.success) {
        this.list = get(result, 'payload.list', []);
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    create() {
      this.$router.push('station-transfer/create');
    },
    viewDetails({ id }) {
      this.$router.push({ name: 'Station Transfer Details', query: { id }});
    }
  }
}
</script>